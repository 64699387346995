import {Engine, Events, Render, Runner, Bodies, Composite, Body, Mouse, MouseConstraint} from 'matter-js';

function rand(min, max) {
    return Math.random() * (max - min) + min;
}

const canvas = document.querySelector('canvas');

var trails = [];
var ballBias = {};
var ballColour = {};

// create an engine
var engine = Engine.create({
    gravity: { x: 0, y: 0 }
});
var canvasMouse = Mouse.create(canvas);
var mConstraint = MouseConstraint.create(engine, { mouse: canvasMouse });

function addBall(x, y, world) {
    var hue = Math.floor(Math.random() * 360);
    var pastel = 'hsl(' + hue + ', 100%, 70%)';
    var ball = Bodies.circle(x, y, 7, {
        label: 'ball-' + rand(10000, 99999) + '-' + Date.now(),
        friction: 0,
        frictionAir: 0.1,
        collisionFilter: {
            category: "ball",
            mask: 1
        },
        render: {
            fillStyle: pastel,
        }
    });
    ballColour[ball.label] = pastel;
    ballBias[ball.label] = {
        'x': rand(0.05, 0.25) * (Math.random() < 0.5 ? -1.0 : 1.0),
        'y': rand(0.05, 0.25) * (Math.random() < 0.5 ? -1.0 : 1.0)
    };
    Composite.add(world, [ball]);
}

function addTrailObj(x, y, world, ball_id, colour) {
    var circle = Bodies.circle(x, y, 3, {
        label: "trail:"+ball_id+":"+Date.now(),
        slop: 3,
        force: {x: 0, y: 0 },
        friction: 0,
        frictionAir: 0,
        render: {
            fillStyle: colour,
            opacity: 0.3,
        },
        collisionFilter: {
            category: "trail",
            mask: 2
        }
    });
    Composite.add(world, circle);
}

// create a renderer
var render = Render.create({
    element: document.body,
    canvas: canvas,
    engine: engine,
    options: {
        width: window.innerWidth,
        height: window.innerHeight,
        background: 'transparent',
        wireframes: false,
        showAngleIndicator: false
    }
});

for(var i = 0; i < 5; i++){
    addBall(rand(window.innerWidth*0.25, window.innerWidth*0.75),
        rand(window.innerHeight*0.15, window.innerHeight*0.85),
        engine.world
    );
}

Events.on(mConstraint, "mouseup", function(event) {
    addBall(event.mouse.position.x, event.mouse.position.y, engine.world);
});


function nextBall(b) {
    var diffY = (Math.floor(Math.random() * 2) - 1) + ballBias[b.label].y;
    var diffX = (Math.floor(Math.random() * 2) - 1) + ballBias[b.label].x;
    Body.setPosition(b, {
        x: Math.ceil(b.position.x) + diffX,
        y: Math.ceil(b.position.y) + diffY,
    }, true);
}

function next(world) {
    var bodies = world.bodies;
    bodies.forEach((b) => {
        if(b.label.startsWith("ball")) {
            nextBall(b);
        }
    });
}
next(engine.world);
const nextInr = setInterval(() => {next(engine.world) }, 250);


 // an example of using beforeUpdate event on an engine
 Events.on(engine, 'afterUpdate', function(event) {
    var bodies = event.source.world.bodies;
    bodies.forEach((b) => {
        // Draw Trail
        if(b.label.startsWith("ball")) {
            var px = parseInt(b.position.x/3)*3;
            var py = parseInt(b.position.y/3)*3;
            var pxy = "id"+b.id+"x"+px+"y"+py;
            if(!trails.includes(pxy)) {
                trails.push(pxy);
                addTrailObj(b.position.x, b.position.y, engine.world, pxy, ballColour[b.label]);
            }
            if ((b.position.x < (-10)) ||
                (b.position.x > (window.innerWidth+10)) ||
                (b.position.y < (-10)) ||
                (b.position.y > (window.innerHeight+10))) {
                    Composite.remove(engine.world, b);
                    addBall(rand(window.innerWidth*0.25, window.innerWidth*0.75),
                        rand(window.innerHeight*0.15, window.innerHeight*0.85),
                        engine.world
                    );
            }
        } else if (b.label.startsWith("trail")) {
            // Remove Trail objects after n seconds of life
            var trail_meta = b.label.split(":");
            if ((Date.now() - trail_meta[2]) > 30000) {
                Composite.remove(engine.world, b);
                trails = trails.filter(function(item) {
                    return item !== trail_meta[1]
                })
            } else {
                b.render.opacity = Math.max(0.1, 0.3 - ((Date.now() - trail_meta[2]) / 40000));
            }
        }
    });
});

// run the renderer
Render.run(render);

// create runner
var runner = Runner.create();

// run the engine
Runner.run(runner, engine);